<template>
  <el-dialog
    title="修改密码"
    :visible.sync="openDialog"
    width="600px"
    :show-close="true"
    append-to-body
    class="dialog-vertical"
    @close="cancel('form')"
  >
    <el-tabs v-model="activeName">
      <el-tab-pane label="修改密码" name="form">
        <div class="form-box">
          <el-form
            size="medium"
            :model="form"
            :rules="rules"
            ref="form"
            label-width="100px"
            v-loading="!pubKey"
            @submit.native.prevent
          >
            <el-form-item label="旧密码：" prop="password">
              <el-input
                style="width: 80%"
                type="password"
                v-model="form.password"
                show-password
              ></el-input>
            </el-form-item>
            <el-form-item label="新密码：" prop="new_password">
              <el-input
                style="width: 80%"
                type="password"
                v-model="form.new_password"
                show-password
              ></el-input>
            </el-form-item>
            <el-form-item label="确认密码：" prop="re_password">
              <el-input
                style="width: 80%"
                type="password"
                v-model="form.re_password"
                show-password
              ></el-input>
            </el-form-item>
            <el-form-item label="提示：">
              <p>1.密码为6-16位</p>
              <p>2.密码至少须要包含 1 位字母</p>
              <p>3.密码至少须要包含 1 位数字</p>
              <p>4.密码至少须要包含 1 位常见特殊字符：!@-_=&lt;&gt;#*%+&^$</p>
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>
      <el-tab-pane label="短信修改密码" name="smsForm">
        <div class="form-box">
          <el-form
            size="medium"
            :model="smsForm"
            :rules="rules"
            ref="smsForm"
            label-width="150px"
            v-loading="!pubKey"
            @submit.native.prevent
          >
            <el-form-item label="管理员手机验证码：" prop="code">
              <div
                :style="{
                  display: 'flex',
                  alignItem: 'center',
                }"
              >
                <el-input
                  :style="{
                    width: '60%',
                    marginRight: '12px',
                  }"
                  type="text"
                  v-model="smsForm.code"
                ></el-input>
                <el-button @click="countDown" :disabled="isSend">{{
                  codeName
                }}</el-button>
              </div>
            </el-form-item>
            <el-form-item label="新密码：" prop="new_password">
              <el-input
                style="width: 80%"
                type="password"
                v-model="smsForm.new_password"
                show-password
              ></el-input>
            </el-form-item>
            <el-form-item label="确认密码：" prop="re_password">
              <el-input
                style="width: 80%"
                type="password"
                v-model="smsForm.re_password"
                show-password
              ></el-input>
            </el-form-item>
            <el-form-item label="提示：">
              <p>1.密码为6-16位</p>
              <p>2.密码至少须要包含 1 位字母</p>
              <p>3.密码至少须要包含 1 位数字</p>
              <p>4.密码至少须要包含 1 位常见特殊字符：!@-_=&lt;&gt;#*%+&^$</p>
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>
    </el-tabs>

    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button type="primary" @click="submit(activeName)">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex'

import regs from '@/base/utils/regs'
import {AdminLogout, changePassword, sendAuthSms, smsChangePassword} from '../api/header'

import { rsaEncrypt } from '@/base/utils/tool'

export default {
  model: {
    prop: 'open',
    event: 'closeDialog',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeName: 'form',
      isSend: false, //禁用
      codeName: '发送验证码',
      totalTime: 60, //一般是60
      timer: '', //定时器
      form: {
        password: '',
        new_password: '',
        re_password: '',
      },
      smsForm: {
        code: '',
        new_password: '',
        re_password:''
      },
      rules: {
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        password: [
          { required: true, message: '请输入旧密码', trigger: 'blur' },
        ],
        new_password: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { validator: regs.complexPass(), trigger: 'blur' },
        ],
        re_password: [
          { required: true, message: '请再次确认密码', trigger: 'blur' },
          { validator: regs.complexPass(), trigger: 'blur' },
        ],
      },
    }
  },
  watch: {
    openDialog(newValue) {
      if (newValue) {
        if (!this.pubKey) {
          this.$store.dispatch('GET_PUB_KEY').catch(() => {})
        }
      }
    },
  },
  methods: {
    // 验证码倒计时
    countDown() {
      if (this.isSend) return
      // 获取验证码的接口
      sendAuthSms().then((res) => {
        console.log(res)
      })
      this.isSend = true
      this.codeName = this.totalTime + 's后重新发送'
      this.timer = setInterval(() => {
        this.totalTime--
        this.codeName = this.totalTime + 's后重新发送'
        if (this.totalTime < 0) {
          clearInterval(this.timer)
          this.codeName = '重新发送验证码'
          this.totalTime = 10
          this.isSend = false
        }
      }, 1000)
    },
    // 获取手机验证码
    getSms() {},
    // 确认
    submit(formName) {
      console.log(formName)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.activeName === 'form') {
            // 旧密码修改密码
            const data = {
              password: rsaEncrypt(this.form.password),
              new_password: rsaEncrypt(this.form.new_password),
              re_password: rsaEncrypt(this.form.re_password),
            }
            changePassword(data).then((res) => {
              this.$message.success(res.msg)
              this.openDialog = false
              this.afterModifiedPassword()
            })
          } else if (this.activeName === 'smsForm') {
            console.log(this.smsForm)
            // 短信修改密码
            const data = {
              code: this.smsForm.code,
              new_password: rsaEncrypt(this.smsForm.new_password),
              re_password: rsaEncrypt(this.smsForm.re_password),
            }
            smsChangePassword(data).then((res) => {
              this.$message.success(res.msg)
              this.openDialog = false
              this.afterModifiedPassword()
            })
          }
        } else {
          return false
        }
      })
    },
    afterModifiedPassword() {
      // 退出重新登录
      AdminLogout().then((res) => {
        this.$store.commit('user/setToken', '')
        this.$router.replace({ name: 'Login' })
      })
    },
    // 取消
    cancel(formName) {
      this.$refs[formName].resetFields()
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('closeDialog', val)
      },
    },
    ...mapState(['pubKey']),
  },
}
</script>

<style lang="scss" scoped>
.form-box {
  margin-top: 24px;
}
.el-form-item {
  p {
    height: 24px;
    color: #c7c7c7;
  }
}
</style>
