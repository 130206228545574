<template>
  <div :class="{ top: level === 0 }" class="nav-content sub" v-if="itemData">
    <!-- 有url的节点，其下的孩子节点作为页面导航出现，不在左侧导航出现 -->
    <template v-if="itemData.url">
      <!-- 链接跳转 -->
      <el-menu-item
        :index="itemData.url"
        @click="openLink(itemData)"
        class="nav-item leaf"
        v-if="/^http/.test(itemData.url) || itemData.is_open_new_window"
      >
        <!-- <img v-if="itemData.icon" class="icon no-active" :src="itemData.icon" />
        <img
          :src="itemData.icon_selected"
          class="icon active"
          v-if="itemData.icon_selected"
        /> -->
        <span slot="title"
          >{{ itemData.name }}
          <el-badge
            :class="{ dot: isCollapse }"
            :hidden="!checkItemChildrenMessage(itemData)"
            :is-dot="isShowDot"
            :max="99"
            :value="checkItemChildrenMessage(itemData) || ''"
            style="overflow: visible"
            class="leaf-badge"
          ></el-badge
        ></span>
      </el-menu-item>
      <!-- 无子菜单 -->
      <el-menu-item
        :index="itemData.url"
        :route="{ name: itemData.url }"
        @click="onRefresh(itemData.url, itemData.name, itemData)"
        class="nav-item leaf"
        v-else
      >
        <!--     :style="{paddingLeft: itemData.icon ? '':'46px'}"    -->
        <!--<img v-if="itemData.icon" class="icon no-active" :src="itemData.icon" />
        <img
          :src="itemData.icon_selected"
          class="icon active"
          v-if="itemData.icon_selected"
        /> -->

        <!--
        :style="{
              transform: isCollapse
                ? ''
                : 'scale(1) translateY(-12px) translateX(-4px)',
            }"
        -->
        <span slot="title"
          >{{ itemData.name }}
          <el-badge
            :class="{ dot: isCollapse }"
            :hidden="!checkItemChildrenMessage(itemData)"
            :is-dot="isShowDot"
            :max="99"
            :value="checkItemChildrenMessage(itemData) || ''"
            style="overflow: visible"

            class="leaf-badge"
          ></el-badge
        ></span>
      </el-menu-item>
    </template>
    <el-menu-item
      :index="itemData.url"
      :key="itemData.name"
      :route="{ name: itemData.url }"
      @click="onRefresh(itemData.url, itemData.name)"
      v-else-if="!itemData.children || !itemData.children.length"
    >
      <i></i>{{ itemData.name }}
      <el-badge
        :class="{ dot: isCollapse }"
        :hidden="!checkItemChildrenMessage(itemData)"
        :is-dot="isShowDot"
        :value="checkItemChildrenMessage(itemData) || ''"
        style="overflow: visible"
        :style="{ transform: isCollapse ? '' : 'scale(0.9)' }"
      ></el-badge>
    </el-menu-item>
    <!-- 有子菜单 -->
    <el-submenu :index="itemData.name" v-else>
      <template slot="title">
        <div class="nav-item">
          <!--<img
            :src="itemData.icon"
            class="icon no-active"
            v-if="itemData.icon"
          />
          <img
            :src="itemData.icon_selected"
            class="icon active"
            v-if="itemData.icon_selected"
          /> -->
          <span slot="title">{{ itemData.name }}</span>
          <el-badge
            :class="{ dot: isCollapse }"
            :hidden="!checkItemChildrenMessage(itemData)"
            :is-dot="isShowDot"
            :value="checkItemChildrenMessage(itemData) || ''"
            style="overflow: visible"
            :style="{ transform: isCollapse ? '' : 'scale(0.9)' }"
          ></el-badge>
        </div>
      </template>
      <template v-for="two in itemData.children">
        <!--    v-if="!two.children || !two.children.length"     -->

        <!--        <el-menu-item :key="two.name" :index="two.url"-->
        <!--                      :route="{ name: two.url }" @click="onRefresh(two.url, two.name)">-->
        <!--          <i></i>{{two.node}}{{ two.name }}-->
        <!--        </el-menu-item>-->
        <sidebar-item-v2 :item-data="two" :level="3" />

        <!--        <el-submenu v-else :index="two.url" :route="{ name: two.url }" class="flex-col nav-item"-->
        <!--                    @click="onRefresh(two.url)">-->
        <!--          <template slot="title"><i style="margin-right: 6px;"></i>{{ two.name }}foo</template>-->
        <!--          <el-menu-item v-for="t in two.children" :key="t.node" :index="t.url"-->
        <!--                        :route="{ name: t.url }" @click="onRefresh(t.url)">{{t.name}}</el-menu-item>-->
        <!--        </el-submenu>-->
      </template>
    </el-submenu>
  </div>
</template>
<script>
import SidebarItem from './SidebarItemV2'
import { mapState } from 'vuex'

export default {
  name: 'sidebar-item-v2',
  components: { SidebarItem },
  props: {
    //每个导航数据
    itemData: {
      type: Object,
      required: true,
    },
    level: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState('socket', ['messagesData']),
    //是否收缩侧边栏
    isCollapse() {
      return this.$store.getters.isCollapse
    },
    routeName() {
      return this.$route.name
    },
    tenantVersion() {
      return this.$store.getters.tenant?.version
    },
    isShowDot() {
      //是否收缩侧边栏
      return this.$store.getters.isCollapse || this.level < 3
    },
  },
  methods: {
    checkItemChildrenMessage(item) {
      if (!this.messagesData) return false

      if (item.url && this.messagesData[item.url] > 0)
        return this.messagesData[item.url]

      const children = item.children || []
      let sum = 0
      for (let i = 0; i < children.length; i++) {
        const current = children[i]
        const _bol = this.checkItemChildrenMessage(current)
        if (_bol) {
          sum += _bol
        }
      }
      return sum
    },
    //外链跳转
    openLink(data) {
      const isOuterLink = /^http/.test(data.url)
      let link
      if (isOuterLink) {
        link = data.url
      } else {
        let routerData = this.$router.resolve({
          name: data.url,
        })
        link = routerData.href
      }
      window.open(link, data.is_open_new_window ? '_blank' : '_self')
      return false
    },
    //菜单事件
    onRefresh(name, title, obj) {
      console.log('点击菜单打开', obj)
      // 刷新
      let queryObj = {
        // query: { title }
      }
      const defaultTab = {
        CycUserList: this.tenantVersion === 'school' ? 'normal' : 'formal',
        ApprovalList: 'handling',
      }
      let paramsObj = {}
      if (Object.keys(defaultTab).includes(name))
        paramsObj.params = { tab: defaultTab[name] }
      if (name === this.routeName) {
        this.$router
          .replace({ name, ...paramsObj, ...queryObj })
          .catch(() => {})
        this.$store.commit('setRefresh', true)
      } else {
        this.$router.push({ name, ...paramsObj, ...queryObj }).catch((err) => {
          // console.log(typeof err) // object
          // console.log(err.type, err.to, err.from, err.name)  // Object.keys(err)
          if (err.name === 'NavigationDuplicated') {
            this.$router
              .replace({ name, ...paramsObj, ...queryObj })
              .catch(() => {})
            this.$store.commit('setRefresh', true)
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$normal-color: #292b2d;

.flex-col {
  ::v-deep.el-submenu__title {
    width: 100%;
  }
}

.icon {
  /* Keyword values */
  image-rendering: auto;
  image-rendering: crisp-edges;
  image-rendering: pixelated;

  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

::v-deep .el-submenu__title {
  color: $normal-color;
  height: 53px;
  line-height: 53px;
  font-weight: bolder;
}

.el-menu .el-submenu.is-opened ::v-deep .el-submenu__title {
  //color: $normal-color;

  .el-icon-arrow-down {
    color: $primary;
  }
}

.el-menu .el-submenu.is-opened ::v-deep > .el-submenu__title {
  //color: $primary;
  border-right-color: transparent;
  background-color: unset;
}

.nav-content.sub {
  .nav-item:not(.leaf) .el-badge:not(.dot) {
    margin-top: -5px;
    margin-left: 5px;
  }

  &.top {
    & > .nav-item.leaf {
      padding-left: 40px !important;

      &:not(.is-active) {
        color: $normal-color;
        /*color: #6E7278;*/
        font-weight: 700;
      }
    }
  }

  & > .nav-item.leaf {
    padding-left: 40px !important;

    &:not(.is-active) {
      //color: $normal-color;
      /*color: #6E7278;*/
    }
  }
}

/* 箭头图标 收起状态 */
::v-deep .el-submenu__icon-arrow.el-icon-arrow-down {
  left: 16px;
  right: unset;
  //color: $normal-color;
  color: inherit;
  transform: rotate(-90deg);
  font-size: 14px;
  top: 26px;
}

/* 箭头图标 展开状态 */
::v-deep .el-submenu.is-opened > .el-submenu__title .el-submenu__icon-arrow {
  transform: rotate(0deg);
}

.nav-item:not(.leaf) {
  padding-left: 20px;
}

.el-menu .nav-item {
  /*justify-content: center;*/
}

/* 二级菜单，没有子级时 */
.top {
  &>.el-menu-item,
  &>.el-submenu__title,
  &>.el-submenu .el-menu-item {
    height: 53px;
    line-height: 53px;
  }
}

/*.el-menu {*/
//hover边框
.el-menu-item,
.el-submenu__title,
.el-submenu .el-menu-item {
  border-right: 3px solid transparent;
  transition: unset;
  /*font-weight: 500;*/
  color: #6e7278;

  &:not(.leaf) {
    height: 53px;
    line-height: 53px;
  }

  &:hover,
  &:active,
  &.is-active {
    background-color: #f5f7fa;
    border-right-color: $primary;
    font-weight: 600;
    color: $primary;
  }
}
/*}*/

.leaf-badge {
  position: absolute;
  /*right: 10px;*/
  right: 7px;
  top: -4px;

  ::v-deep .el-badge__content {
    /*padding-top: 5px;*/
    height: 22px;
    min-width: 22px;

    font-size: 12px;
    font-weight: 700;
    line-height: 22px;

    display: flex;
    padding: 2px 6px;
    justify-content: center;
    align-items: center;

    border-radius: 28px;
    background: #FF1C29;

    border: none;
  }
}
</style>
