<!--
 * @Author: mulingyuer
 * @Date: 2021-08-03 10:59:29
 * @LastEditTime: 2021-09-07 17:59:26
 * @LastEditors: mulingyuer
 * @Description: 媒体库弹窗
 * @FilePath: \saas-admin-vue\src\modules\common\components\MediaDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <BaseMediaDialog :visible="show" :onUpload="uploadApi" :loading="loading" :onQuery="onQuery"
    :list="pageData" :folders="folderOptions" :multiple="options.multiple" :max="options.max"
    :type="options.type" @confirm="onConfirm" @close="onClose" />
</template>

<script>
import BaseMediaDialog from "@/base/components/Media/BaseMediaDialog";
import upload from "@/base/utils/upload4";
import { mediaList } from "@/modules/media/api/media";
import {randomString} from '../../../base/utils/tool'
export default {
  provide() {
    return {
      mediaKey: this.mediaKey
    }
  },
  data() {
    return {
      mediaKey: randomString(),
      show: false, //是否显示
      loading: false, //获取中
      pageData: {}, //媒体库每页数据
      //配置
      options: {
        multiple: false, //是否单选
        max: 99, //多选最多选多少个
        type: ["folder", "file", "image", "video", "audio"], //支持上传的文件类型
      },
    };
  },
  computed: {
    //文件夹选项
    folderOptions({ $store }) {
      return $store.state.media.folderOptions;
    },
  },
  methods: {
    //上传api
    uploadApi({ files, callback, progress }) {
      upload({
        configApi: "/admin/admin/media/requestUpload",
        data: files,
        progress,
      }).then((res) => {
        return callback(res);
      }).catch(err => {
        callback(err)
      });
    },
    //显示弹窗
    showDialog({ confirm = () => {}, options = {} } = {}) {
      Object.assign(this.options, options);
      this.confirm = confirm; //记录回调
      this.show = true;
    },
    //查询列表
    onQuery(query) {
      this.loading = true;
      if (this.folderOptions.length) {
        this.getList(query);
      } else {
        this.$store
          .dispatch("media/getFoldersData")
          .then(() => {
            this.getList(query);
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    //获取列表数据
    getList(query) {
      mediaList(query)
        .then((res) => {
          const { data } = res;
          this.pageData = data;

          this.loading = false;

          this.$store.commit('media/setIsRefresh', false)
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //确认
    onConfirm(data) {
      if (this.confirm) this.confirm(data);
    },
    //取消
    onClose() {
      this.show = false;
      //还原数据
      this.confirm = null;
      Object.assign(this.$data, this.$options.data());
    },
  },
  created() {
    //监听
    this.$bus.on("open-media-dialog", this.showDialog);
  },
  beforeDestroy() {
    //取消监听
    this.$bus.off("open-media-dialog", this.showDialog);
  },
  components: {
    BaseMediaDialog,
  },
};
</script>

<style lang="scss" scoped>
</style>
