<template>
  <div class="nav-content sub" v-if="itemData">
    <!-- 有url的节点，其下的孩子节点作为页面导航出现，不在左侧导航出现 -->
    <template v-if="itemData.url" class="nav-item">
      <!-- 链接跳转 -->
      <el-menu-item
        :index="itemData.url"
        @click="openLink(itemData)"
        class="nav-item"
        v-if="/^http/.test(itemData.url) || itemData.is_open_new_window"
      >
        <img v-if="itemData.icon" class="icon no-active" :src="itemData.icon" />
        <img
          :src="itemData.icon_selected"
          class="icon active"
          v-if="itemData.icon_selected"
        />
        <span slot="title">{{ itemData.name }}   <el-badge
            :class="{'dot': isCollapse}"
            :hidden="!checkItemChildrenMessage(itemData)" :is-dot="isShowDot"
            :value="checkItemChildrenMessage(itemData) || ''"
            style="overflow: visible"
        ></el-badge></span>
      </el-menu-item>
      <!-- 无子菜单 -->
      <el-menu-item
        :index="itemData.url"
        :route="{ name: itemData.url }"
        @click="onRefresh(itemData.url, itemData.name, itemData)"
        class="nav-item leaf"
        v-else
      >
        <!--     :style="{paddingLeft: itemData.icon ? '':'46px'}"    -->
        <img v-if="itemData.icon" class="icon no-active" :src="itemData.icon" />
        <img
          :src="itemData.icon_selected"
          class="icon active"
          v-if="itemData.icon_selected"
        />
        <span class="title-after-icon" slot="title"
          >{{ itemData.name }}
          <el-badge
            :class="{'dot': isCollapse}"
            :hidden="!checkItemChildrenMessage(itemData)" :is-dot="isShowDot"
            :value="checkItemChildrenMessage(itemData) || ''"
            style="overflow: visible"
          ></el-badge
        ></span>
      </el-menu-item>
    </template>
    <el-menu-item
      :index="itemData.url"
      :key="itemData.name"
      :route="{ name: itemData.url }"
      @click="onRefresh(itemData.url, itemData.name)"
      v-else-if="!itemData.children || !itemData.children.length"
    >
      <i></i>{{ itemData.name }}
      <el-badge
        :class="{'dot': isCollapse}"
        :hidden="!checkItemChildrenMessage(itemData)" :is-dot="isShowDot"
        :value="checkItemChildrenMessage(itemData) || ''"
        style="overflow: visible"
      ></el-badge>
    </el-menu-item>
    <!-- 有子菜单 -->
    <el-submenu v-else :index="itemData.name">
      <template slot="title">
        <div class="nav-item">
          <img
            :src="itemData.icon"
            class="icon no-active"
            v-if="itemData.icon"
          />
          <img
            :src="itemData.icon_selected"
            class="icon active"
            v-if="itemData.icon_selected"
          />
          <span slot="title">{{ itemData.name }}</span>
          <el-badge
            :class="{'dot': isCollapse}"
            :hidden="!checkItemChildrenMessage(itemData)" :is-dot="isShowDot"
            :value="checkItemChildrenMessage(itemData) || ''"
            style="overflow: visible"
          ></el-badge>
        </div>
      </template>
      <template v-for="two in itemData.children">
        <!--    v-if="!two.children || !two.children.length"     -->

        <!--        <el-menu-item :key="two.name" :index="two.url"-->
        <!--                      :route="{ name: two.url }" @click="onRefresh(two.url, two.name)">-->
        <!--          <i></i>{{two.node}}{{ two.name }}-->
        <!--        </el-menu-item>-->
        <sidebar-item :item-data="two" :level="3" />

        <!--        <el-submenu v-else :index="two.url" :route="{ name: two.url }" class="flex-col nav-item"-->
        <!--                    @click="onRefresh(two.url)">-->
        <!--          <template slot="title"><i style="margin-right: 6px;"></i>{{ two.name }}foo</template>-->
        <!--          <el-menu-item v-for="t in two.children" :key="t.node" :index="t.url"-->
        <!--                        :route="{ name: t.url }" @click="onRefresh(t.url)">{{t.name}}</el-menu-item>-->
        <!--        </el-submenu>-->
      </template>
    </el-submenu>
  </div>
</template>
<script>
import SidebarItem from './SidebarItem'
import { mapState } from 'vuex'

export default {
  name: 'sidebar-item',
  components: { SidebarItem },
  props: {
    //每个导航数据
    itemData: {
      type: Object,
      required: true
    },
    level: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapState('socket', ['messagesData']),
    //是否收缩侧边栏
    isCollapse() {
      return this.$store.getters.isCollapse;
    },
    routeName() {
      return this.$route.name
    },
    tenantVersion() {
      return this.$store.getters.tenant?.version
    },
    isShowDot() {
      //是否收缩侧边栏
      return this.$store.getters.isCollapse || this.level < 3
    }
  },
  methods: {
    checkItemChildrenMessage(item) {
      if (!this.messagesData) return false

      if (item.url && this.messagesData[item.url] > 0)
        return this.messagesData[item.url]

      const children = item.children || []
      let sum = 0
      for (let i = 0; i < children.length; i++) {
        const current = children[i]
        const _bol = this.checkItemChildrenMessage(current)
        if (_bol) {
          sum += _bol
        }
      }
      return sum
    },
    //外链跳转
    openLink(data) {
      const isOuterLink = /^http/.test(data.url)
      let link
      if (isOuterLink) {
        link = data.url
      } else {
        let routerData = this.$router.resolve({
          name: data.url,
        })
        link = routerData.href
      }
      window.open(link, data.is_open_new_window ? '_blank' : '_self')
      return false
    },
    //菜单事件
    onRefresh(name, title, obj) {
      console.log('点击菜单打开', obj)
      // 刷新
      let queryObj = {
        // query: { title }
      }
      const defaultTab = {
        CycUserList: this.tenantVersion === 'school' ? 'normal' : 'formal',
        ApprovalList: 'handling',
      }
      let paramsObj = {}
      if (Object.keys(defaultTab).includes(name))
        paramsObj.params = { tab: defaultTab[name] }
      if (name === this.routeName) {
        this.$router
          .replace({ name, ...paramsObj, ...queryObj })
          .catch(() => {})
        this.$store.commit('setRefresh', true)
      } else {
        this.$router.push({ name, ...paramsObj, ...queryObj }).catch((err) => {
          // console.log(typeof err) // object
          // console.log(err.type, err.to, err.from, err.name)  // Object.keys(err)
          if (err.name === 'NavigationDuplicated') {
            this.$router
              .replace({ name, ...paramsObj, ...queryObj })
              .catch(() => {})
            this.$store.commit('setRefresh', true)
          }
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.flex-col {
  ::v-deep.el-submenu__title {
    width: 100%;
  }
}

.icon {
  /* Keyword values */
  image-rendering: auto;
  image-rendering: crisp-edges;
  image-rendering: pixelated;

  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

.el-menu .el-submenu.is-opened ::v-deep .el-submenu__title {
  color: inherit;
}

.el-menu .el-submenu.is-opened ::v-deep > .el-submenu__title {
  color: $primary;
}

.nav-content.sub {
  .el-badge:not(.dot) {
    margin-top: -5px;
    margin-left: 5px;
  }
}

.icon + .title-after-icon {
  padding-left: 6px;
}
</style>
