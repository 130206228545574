<template>
  <div id="header">
    <div class="left">
      <router-link @click.native="jumpOverview" to="" class="logo">
        <img v-if="logoSrc" :src="logoSrc" />
      </router-link>
      <!-- <span
        class="menu"
        :class="isCollapse ? 'unfold' : 'fold'"
        @click="toggleCollapse"
      >
        <i></i>
      </span> -->
    </div>
    <div class="middle flex-align-center">
      <el-cascader
        :value="searchMenu"
        :options="mySidebarData"
        :props="{
          checkStrictly: false,
          expandTrigger: 'hover',
          label: 'name',
          value: 'url',
          children: 'children',
          emitPath: false,
          multiple: false,
        }"
        filterable
        clearable
        @change="onSearch"
        ref="CascaderRef"
        placeholder="搜索菜单"
        popper-class="search-menu"
        class="search-menu"
      >
        <template #default="{ node, data }">
          <div class="flex-between" style="">
            <div>{{ data.name }}</div>
            <div @click.stop.prevent class="flex _blank">
              <!--              <el-button type="text">直接打开</el-button>-->
              <!--              <el-button @click="" type="text">新标签页打开</el-button>-->
              <router-link target="_blank" :to="{ name: data.url }">
                <i class="el-icon-top-right cursor-pointer"></i>
              </router-link>
            </div>
          </div>
        </template>
      </el-cascader>
      <slot></slot>
    </div>
    <div class="right">
      <!-- <a class="nav-link cursor-pointer" v-for="(jump, index) in fastJumps" :key="index" :title="jump.name"
        @click="jumpLink(jump)">
        <img class="nav-logo" :src="jump.logo" :alt="jump.name" />
      </a> -->
      <!-- 跳转社团云官网 -->
      <!--      <router-link to class="nav-link">-->
      <!--        <i class="el-icon-s-home"></i>-->
      <!--      </router-link>-->
      <!--      <router-link to class="nav-link">-->
      <!--        <i class="el-icon-refresh"></i>-->
      <!--      </router-link>-->
      <!-- <div class="right-item"> -->
      <!-- <el-tooltip effect="dark" content="通知" placement="bottom"> -->
      <!-- <a class="tooltip" href="javascript:;">
          <el-dropdown trigger="click" placement="bottom" class="notice-dropdown"
            @command="onNoticeDropdownCommand">
            <div class="item-content">
              <el-badge :is-dot="isDot" class="badge">
                <div class="item-icon notice"></div>
              </el-badge>
            </div>
            <el-dropdown-menu slot="dropdown">
              <div class="notice-dropdown-content">
                <div class="notice-dropdown-head">
                  <h2 class="title">消息中心</h2>
                </div>
                <el-dropdown-item v-for="item in msgData" :key="item.name" :command="item.type">
                  <span>{{item.name}}</span>
                  <span>（<strong>{{item.count}}</strong>）</span>
                </el-dropdown-item>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </a> -->
      <!-- </el-tooltip> -->
      <!-- </div> -->
      <!-- TODO: 【此版本先隐藏】用户中心的消息 -->
      <!--      <div class="right-item" :class="{ active: openUserCenterNotification }">-->
      <!--        <el-tooltip effect="dark" content="通知" placement="bottom">-->
      <!--          <a href="javascript:;" class="tooltip" @click="onOpenUserCenterNotification">-->
      <!--            <div class="item-content">-->
      <!--              <el-badge-->
      <!--                  :value="userCenterNotifications.total"-->
      <!--                  :max="99"-->
      <!--                  class="badge"-->
      <!--                  :hidden="userCenterNotifications.total <= 0"-->
      <!--              >-->
      <!--                <div class="item-icon notice"></div>-->
      <!--              </el-badge>-->
      <!--            </div>-->
      <!--          </a>-->
      <!--        </el-tooltip>-->
      <!--      </div>-->
      <div class="right-item" v-if="isDev || $store.getters.tenant.features.includes('ai')">
        <el-tooltip content="AI助手" effect="dark" placement="bottom">
          <a @click="clickAI" class="tooltip" href="">
            <div class="item-content">
              <span style="text-align: center;    font-variant-numeric: lining-nums tabular-nums;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    background: linear-gradient(116deg, #D04BFF 16.34%, #FE7ED2 48.79%, #FFB4FC 83.66%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    width: 24px;
    height: 24px;">AI</span>
            </div>
          </a>
        </el-tooltip>
      </div>

      <div class="right-item" :class="{ active: openNotification }">
        <el-tooltip effect="dark" content="通知" placement="bottom">
          <a @click="onOpenNotification" class="tooltip" href="#">
            <div class="item-content">
              <el-badge
                :value="msgData"
                :max="99"
                class="badge"
                :hidden="msgData <= 0"
              >
                <div class="item-icon notice"></div>
              </el-badge>
            </div>
          </a>
        </el-tooltip>
      </div>
      <div class="right-item" v-for="(jump, index) in fastJumps" :key="index">
        <el-tooltip effect="dark" :content="jump.name" placement="bottom">
          <a class="tooltip" :href="jump.url" target="_brank">
            <div class="item-content">
              <el-image
                class="item-icon"
                :src="jump.logo_normal"
                fit="cover"
              ></el-image>
              <el-image
                class="item-icon active"
                :src="jump.logo"
                fit="cover"
              ></el-image>
            </div>
          </a>
        </el-tooltip>
      </div>
      <div class="right-item">
        <el-popover
          trigger="click"
          placement="bottom"
          :width="562"
          :visible-arrow="false"
          @show="appDialog.isInitTabs = true"
          @hide="appDialog.isInitTabs = false"
        >
          <!-- 触发弹窗的内容 -->
          <div class="tooltip cursor-pointer" slot="reference">
            <el-tooltip effect="dark" content="客户端预览" placement="bottom">
              <div class="item-content">
                <el-image
                  class="item-icon"
                  :src="require('../assets/images/app_normal.png')"
                  fit="cover"
                />
                <el-image
                  class="item-icon active"
                  :src="require('../assets/images/app_hover.png')"
                  fit="cover"
                />
              </div>
            </el-tooltip>
          </div>
          <!-- 弹窗内容 -->
          <div style="min-height: 290px; padding: 20px">
            <h3 class="popover-title">客户端预览</h3>
            <el-tabs v-if="appDialog.isInitTabs" v-model="appDialog.activeName">
              <el-tab-pane v-if="isWxApplet" label="微信小程序" name="mp">
                <div
                  v-loading="appDialog.loading"
                  class="popover-content"
                  :class="{ 'flex-center': appDialog.mpList.length }"
                  style="padding-top: 24px"
                >
                  <template
                    v-if="!appDialog.loading && appDialog.mpList.length"
                  >
                    <div
                      class="popover-qrcode-box flex-col flex-center"
                      v-for="item in appDialog.mpList"
                      :key="item.label"
                    >
                      <img
                        class="popover-qrcode"
                        :src="item.qrcode"
                        :alt="item.label"
                      />
                      <span class="popover-qrcode-name">{{ item.label }}</span>
                    </div>
                  </template>
                  <p
                    v-else-if="!appDialog.loading"
                    style="margin-top: 70px; text-align: center"
                  >
                    当前未绑定小程序，
                    <router-link :to="{ name: 'AppletBaseInfo' }">
                      <el-button type="text" size="medium" style="padding: 0"
                        >前往绑定
                      </el-button>
                    </router-link>
                  </p>
                </div>
              </el-tab-pane>
              <el-tab-pane v-if="isH5" label="H5 微网站" name="h5">
                <div
                  v-loading="appDialog.loading"
                  class="popover-content flex-center"
                  style="padding-top: 24px"
                >
                  <div
                    class="popover-qrcode-box flex-col flex-center"
                    v-for="item in appDialog.h5List"
                    :key="item.label"
                  >
                    <img
                      class="popover-qrcode"
                      :src="item.qrcode"
                      :alt="item.label"
                    />
                    <div style="margin-top: 12px">
                      <span class="popover-qrcode-name">{{ item.label }}</span>
                      <!--                      <el-button size="medium" type="text" style="padding: 0; margin-left: 10px;" @click="handleCopy(item.url)">复制H5链接</el-button>-->
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-popover>
      </div>
      <!-- <div class="right-item">
        <el-tooltip effect="dark" content="刷新" placement="bottom">
          <a class="tooltip" href="javascript:;" @click.prevent="clearCache">
            <div class="item-content">
              <div class="item-icon reload"></div>
            </div>
          </a>
        </el-tooltip>
      </div> -->
      <div class="right-dropdown">
        <el-dropdown
          trigger="click"
          placement="bottom"
          @command="onDropdownCommand"
        >
          <div class="dropdown-item">
            <el-image class="avatar" :src="avatarSrc" fit="cover"></el-image>
            <span class="name">{{
              nickname ? nickname : '未登录' | placeholder
            }}</span>
            <i class="el-icon-arrow-down dropdown-icon"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-user" command="userCenter"
              >个人中心
            </el-dropdown-item>
            <el-dropdown-item icon="el-icon-unlock" command="changePass"
              >修改密码
            </el-dropdown-item>
            <el-dropdown-item icon="el-icon-switch-button" command="signOut"
              >退出登录
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <PassWordDialog v-model="openPasswordDialog" />
    <!-- 消息中心 -->
    <Notification v-model="openNotification" />
    <!-- 用户中心的消息 -->
    <user-center-notification
      v-model="openUserCenterNotification"
      @update="updateUserCenterMessages"
    />
  </div>
</template>
<script>
import { headerData, AdminLogout, getCode } from '../api/header'

import { mapState } from 'vuex'
import PassWordDialog from './PassWordDialog.vue'
import Notification from './Notification'
import { userCenterMessages } from '@/modules/admin/api/notification'
import UserCenterNotification from '@/modules/admin/components/UserCenterNotification'

// import {copyText} from "@/base/utils/tool";

export default {
  props: {
    //关闭侧边栏子菜单
    offSidebarChildren: {
      type: Function,
      default: () => {},
    },
    fullscreenNames: Array,
  },
  components: { UserCenterNotification, PassWordDialog, Notification },
  data() {
    return {
      logiSrc: '',
      site: {},
      openPasswordDialog: false,
      appDialog: {
        isInitTabs: false,
        activeName: 'mp',
        mpList: [],
        h5List: [],
        loading: true,
      },
      openNotification: false, //是否显示消息中心
      openUserCenterNotification: false, // 用户中心消息
      userCenterNotifications: {},

      searchMenu: '',
    }
  },
  computed: {
    ...mapState([
      // 是否收缩侧边栏
      'isCollapse',
      // 目前包含 token、avatar、nickname
      'user',
      'header',
    ]),
    mySidebarData() {
      function _handleChildren(a) {
        return a.map((el) => {
          el.url =
            el.url ||
            `_${el.node}` /* url 可能为空，为了 key 值唯一， 补一个假的 url */
          if (el.children === undefined) {
            // el.children = []
          } else {
            el.children = _handleChildren(el.children)
          }
          return el
        })
      }

      const sourceData = JSON.parse(
        JSON.stringify(this.$store.state.sidebar.sidebarData)
      )
      return _handleChildren(sourceData)
    },
    //header数据
    headerData() {
      return this.$store.getters['header/headerData']
    },
    fastJumps({ headerData }) {
      return headerData.admin_fast_jumps
        ? headerData.admin_fast_jumps.filter((el) => el.enable)
        : []
    },
    //logo链接
    logoSrc({ headerData }) {
      return headerData.admin_nav_logo || ''
    },
    //头像链接
    avatarSrc({ headerData }) {
      return (
        headerData?.admin_user?.avatar ||
        window.serverConfig.VUE_APP_ADMINURL +
          '/cyc/images/wxapps/icons/avatar1.png'
      )
    },
    //昵称
    nickname({ headerData }) {
      return headerData?.admin_user?.nickname || ''
    },
    //消息数据
    msgData({ headerData }) {
      return headerData.new_message_count || 0
    },
    //是否显示消息角标
    isDot({ msgData }) {
      return msgData.length >= 2
    },
    isWxApplet() {
      return this.$store.getters.tenant.features.includes('wx_applet')
    },
    isH5() {
      return this.$store.getters.tenant.features.includes('h5')
    },
    isDev() {
      return process.env.NODE_ENV === 'development' || process.env.VUE_APP_MODE === 'development'
    }
  },
  methods: {
    clickAI(e) {
      e.preventDefault()

      this.$store.dispatch("header/openChat", {
        foo: 'bar'
      });
    },
    onSearch(e) {
      this.searchMenu = e
      if (!this.searchMenu) return // empty or null

      // todo:
      const routeObj = {
        name: this.searchMenu, // 前端使用了 menu/index url 关联路由的 name
      }

      // this.$confirm('是否使用新标签页打开？', "提示", {
      //   type: "info",
      //   distinguishCancelAndClose: true,
      //   cancelButtonText: '当前窗口打开'
      // }).then(() => {
      //   let routerData = this.$router.resolve(routeObj);
      //   window.open(routerData.href, "_blank");
      // }).catch(() => {
      this.$router.push(routeObj).finally(() => {
        this.searchMenu = ''
      })
      // })
    },
    toIframe() {
      const name = 'Iframe'
      if (name === this.$route.name) {
        this.$router
          .replace({
            name,
            query: {
              url: encodeURIComponent(
                window.serverConfig.VUE_APP_OTHER_BASEURL['user-admin']
              ),
              // url: encodeURIComponent('http://localhost:8099/admin/my-project/')
            },
          })
          .catch(() => {})
        this.$store.commit('setRefresh', true)
      } else {
        this.$router
          .push({
            name,
            query: {
              url: encodeURIComponent(
                window.serverConfig.VUE_APP_OTHER_BASEURL['user-admin']
              ),
              // url: encodeURIComponent('http://localhost:8099/admin/my-project/')
            },
          })
          .catch(() => {})
      }
    },
    // handleCopy(url){
    //   copyText(url).then(res => {
    //     this.$message.success('复制成功')
    //   }).catch(err => {
    //   })
    // },
    //打开消息中心
    onOpenNotification(event) {
      this.openNotification = true
      event.currentTarget.blur() //去除重复显示tooltips
      event.preventDefault(); // 代替 unsafe-inline javascript [CSP Error]
    },
    onOpenUserCenterNotification(event) {
      this.openUserCenterNotification = true
      event.currentTarget.blur() //去除重复显示tooltips
    },
    //折叠侧边栏
    toggleCollapse() {
      this.$store.commit('setIsCollapse', !this.isCollapse)
    },
    //下拉菜单事件
    onDropdownCommand(name) {
      switch (name) {
        case 'userCenter': //个人中心
          this.toUserCenter()
          this.offSidebarChildren()
          break
        case 'changePass': //修改密码
          this.openPasswordDialog = true
          break
        case 'signOut': //退出登录
          this.signout()
          break
      }
    },
    // 点击logo跳转
    jumpOverview() {
      if (
        JSON.parse(sessionStorage.getItem('sidebarData'))[0].url ===
          'Overview' &&
        this.$route.name !== 'Overview'
      ) {
        this.$router.push({
          name:
            JSON.parse(sessionStorage.getItem('sidebarData'))[0].url ||
            JSON.parse(sessionStorage.getItem('sidebarData'))[0].children[0]
              .url,
        })
      }
    },
    //跳转个人中心
    toUserCenter() {
      if (this.$route.name === 'UserIndex') {
        this.$store.commit('setRefresh', true)
      } else {
        this.$router.push({ name: 'UserIndex' })
      }
    },
    // 跳转外部链接
    jumpLink(linkData) {
      window.open(linkData.url, '_blank')
    },
    //退出登录
    signout() {
      this.$msgbox
        .confirm('您是否要退出登录？', '提示', {
          type: 'warning',
        })
        .then((res) => {
          AdminLogout().then((res) => {
            this.$message.success(res.msg)
            this.$store.commit('user/setToken', '')
            this.$router.replace({ name: 'Login' })
          })
        })
        .catch((err) => {})
    },
    //清理缓存
    clearCache() {
      this.$confirm('清理缓存将刷新页面, 是否清理缓存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          const href = window.location.href
          window.location.replace(href)
        })
        .catch(() => {
          //跳过
        })
    },
    //消息通知菜单事件
    onNoticeDropdownCommand(type) {
      const { name, query } = this.$route
      if (name === 'MessageCenterList' && typeof query.type !== 'undefined') {
        if (Number(query.type) !== type) {
          this.$router.push({ query: { type } })
        } else {
          this.$store.commit('setRefresh', true)
        }
      } else {
        this.$router.push({ name: 'MessageCenterList', query: { type } })
      }
    },
    //获取header数据
    // getHeaderData() {
    //   if (["AppDesignPage", "FormPage"].includes(this.$route.name)) return;
    //   headerData()
    //     .then((res) => {
    //       const { data } = res;
    //       data.admin_fast_jumps = data.admin_fast_jumps || [];
    //       this.site = data;
    //       this.$store.commit("header/setIsRefresh", false);
    //       this.$store.commit("header/setHeaderData", data);
    //       this.$store.commit("setAvatar", data.admin_user.avatar);
    //       this.$store.commit("setNickname", data.admin_user.nickname);
    //       // this.getAuthStatus();
    //     })
    //     .catch((err) => {});
    // },
    // 获取小程序授权状态
    // getAuthStatus() {
    //   authStatus()
    //     .then((res) => {
    //       const { data } = res;
    //       this.$store.commit("setAuthStatus", data.status);
    //     })
    //     .catch((err) => {});
    // },
    /**
     * 获取客户端的预览码
     * @param {number} type 类型 0: 小程序 1: H5
     */
    getPreCodes(type) {
      if (this.appDialog[type ? 'h5List' : 'mpList'].length) return

      this.appDialog.activeName = this.isWxApplet ? 'mp' : 'h5'

      this.appDialog.loading = true
      getCode(type ? 'h5' : 'mp-weixin')
        .then((res) => {
          this.appDialog[type ? 'h5List' : 'mpList'] = res.data
          this.appDialog.loading = false
        })
        .catch((err) => {
          this.appDialog.loading = false
        })
    },
    updateUserCenterMessages() {
      // 将用户中心的消息搬来这里
      userCenterMessages()
        .then((res) => {
          this.userCenterNotifications = res.data
        })
        .catch(() => {})
    },
  },
  created() {
    const useStoredList = this.fullscreenNames || []
    if (useStoredList.includes(this.$route.name)) {
      // use store data
    } else {
      // 获取header数据
      this.$store.dispatch('header/getHeaderData')
    }

    // if (!headerData) {
    //   this.getHeaderData();
    // } else {
    //   this.site = headerData;
    // }

    // TODO：先隐藏用户中心消息数
    // if (this.headerData && this.headerData.admin_user.is_super_admin) {
    //   this.updateUserCenterMessages()
    // }
  },
  watch: {
    //   "header.isRefresh"(val) {
    //     if (val) this.getHeaderData();
    //   },
    'appDialog.activeName'(val) {
      switch (val) {
        case 'mp':
          this.getPreCodes(0)
          break
        case 'h5':
          this.getPreCodes(1)
          break
      }
    },
    'appDialog.isInitTabs'(val) {
      if (val) {
        this.getPreCodes(0)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.popover-title {
  font-size: 16px;
  font-weight: 600;
  color: #1a1a1a;
  line-height: 24px;
  margin-bottom: 10px;
}

.popover-content {
  min-height: 154px;

  .popover-qrcode-box {
    .popover-qrcode {
      width: 154px;
      height: 154px;
      background-color: $placeholder;
    }

    .popover-qrcode-name {
      margin-top: 12px;
      font-size: 14px;
      color: #000000;
      line-height: 21px;
    }
  }

  .popover-qrcode-box + .popover-qrcode-box {
    margin-left: $space * 6;
  }
}

#header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: $header-h;
  background-color: #fff;
  box-shadow: 1px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 11;
  display: flex;
  justify-content: space-between;

  .middle {
    padding-right: 50%;
    //padding-left: $space;
    width: calc(100% - #{var(--left-w)});

    .el-cascader {
      width: 300px;
    }
  }

  .left,
  .right {
    display: flex;
    align-items: center;
  }

  .left {
    padding-left: 8px;

    .logo {
      //width: $sidebar-w;
      width: 160px;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .menu {
      padding: 15px;
      color: $text-muted;
      font-weight: bold;
      cursor: pointer;

      i {
        display: block;
        width: 16px;
        height: 16px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }

      &.fold {
        i {
          background-image: url('../assets/images/icon-no-collapse.png');
        }
      }

      &.unfold {
        i {
          background-image: url('../assets/images/icon-collapse.png');
        }
      }
    }
  }

  .right {
    position: absolute;
    top: 0;
    left: calc(100vw - 20px);
    height: 100%;
    transform: translateX(-100%);

    .right-item {
      width: 50px;
      height: 100%;
      position: relative;

      .item-content .active {
        display: none;
      }

      &:hover,
      &:active,
      &.active {
        .tooltip {
          border-color: #3576ff;
        }

        .item-content .item-icon {
          display: none;
        }

        .item-content .active {
          display: block;
        }

        .item-content .item-icon {
          &.notice {
            background-image: url('../assets/images/icon-notice-active.png');
            display: block;
          }
        }
      }

      .tooltip {
        display: block;
        height: 100%;
        border-top: 3px solid transparent;
        transition: border-color 0.25s;
      }

      .notice-dropdown {
        display: block;
        height: 100%;
      }

      .item-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .badge {
          ::v-deep.el-badge__content {
            padding: 0 4px;

            &.is-dot {
              padding: 0;
              top: 5px;
              right: 50%;
              width: 10px;
              height: 10px;
              background-color: $danger;
            }
          }
        }

        .item-icon {
          width: 24px;
          height: 24px;
          background-repeat: no-repeat;
          background-size: 24px 24px;
          background-position: center;
          transition: background-image 0.25s;

          &.notice {
            background-image: url('../assets/images/icon-notice.png');
          }
        }
      }
    }

    .right-dropdown {
      height: 100%;
      margin-left: 10px;

      .el-dropdown {
        display: block;
        height: 100%;
      }

      .dropdown-item {
        max-width: 142px;
        height: 100%;
        display: flex;
        align-items: center;
        @include no-select();
        cursor: pointer;

        .avatar {
          flex-shrink: 0;
          border-radius: 50%;
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }

        .name,
        .dropdown-icon {
          font-size: 14px;
          line-height: 1;
          color: #3576ff;
        }

        .name {
          min-width: 0;
          flex-grow: 1;
          @include nowrap();
        }

        .dropdown-icon {
          flex-shrink: 0;
          margin-left: 5px;
        }
      }
    }
  }
}

.right-dropdown {
  height: 100%;

  .el-dropdown {
    display: block;
    height: 100%;
  }

  .dropdown-item {
    max-width: 142px;
    height: 100%;
    display: flex;
    align-items: center;
    @include no-select();
    cursor: pointer;

    .avatar {
      flex-shrink: 0;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    .name,
    .dropdown-icon {
      font-size: 14px;
      line-height: 1;
      color: #3576ff;
    }

    .name {
      min-width: 0;
      flex-grow: 1;
      @include nowrap();
    }

    .dropdown-icon {
      flex-shrink: 0;
      margin-left: 5px;
    }
  }
}

::v-deep.search-menu {
  .el-input__inner {
    border-radius: 20px;
  }

  .el-input__suffix .el-icon-arrow-down {
    display: none;
  }
}
</style>

<style lang="scss">
.search-menu.el-popper {
  /*&:not(.visible) {*/
  /*opacity: 0;*/
  /*box-shadow: unset;*/
  /*border-color: transparent;*/
  /*}*/

  .el-cascader-node {
    /*padding-right: 5px;*/
  }

  .el-cascader-node__postfix {
    /*display: none;*/
  }

  :not(.el-cascader__suggestion-panel) {
    ._blank {
      display: none;
    }
  }

  .el-cascader__suggestion-list {
    max-height: 60vh;
  }
}

.search-menu .el-cascader-menu__wrap {
  height: fit-content;
  max-height: 60vh;
  /*height: 0;*/
}

.search-menu .el-cascader-panel {
  /*height: 0;*/
  /*box-shadow: unset;*/
  /*height: fit-content;*/
  /*border-color: transparent;*/
}

.search-menu .el-cascader-panel .el-cascader__dropdown,
.search-menu .el-cascader-menu__wrap,
.search-menu .el-cascader-menu {
  box-shadow: unset;
}

.search-menu .el-cascader__suggestion-panel {
  /*box-shadow: unset;*/

  .el-scrollbar__wrap {
    height: fit-content;
    max-height: 60vh;
  }
}

.search-menu .popper__arrow {
  opacity: 0;
}
</style>
