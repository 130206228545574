<!--
 * @Author: dongjia
 * @Date: 2022-04-19 09:49:36
 * @LastEditTime: 2022-04-19 10:43:46
 * @LastEditors: aleaner
 * @Description: 商会共享弹窗
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\admin\components\ServerTipsDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog
    title=""
    :visible.sync="openDialog"
    width="1200px"
    class="dialog-vertical"
    close-on-click-modal
    close-on-press-escape
    :show-close="false"
  >
    <div>
      <el-image
        class="cover"
        :src="platformPromotionIcon"
        @click="goToPromotion"
      >
      </el-image>
    </div>

    <!-- <span slot="footer">
      <el-button type="primary" @click="goToPromotion">前往开通</el-button>
    </span> -->
  </el-dialog>
</template>

<script>
const resUrl = window.serverConfig.VUE_APP_ADMINURL
import { recordMsgCount } from '@/modules/admin/api/header'
export default {
  model: {
    prop: 'open',
    event: 'closeDialog',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      resUrl,
      platformPromotionIcon: `${resUrl}/admin/images/platform-promotion.jpg`,
    }
  },
  methods: {
    // 获取项目主颜色
    mainColor(item) {
      return item.type === 'package' ? '#E63C3C' : '#E6A23C'
    },
    // 获取项目副颜色
    subColor(item) {
      return item.type === 'package' ? '#FDECEC' : '#FDF6EC'
    },
    // 前往开通
    goToPromotion() {
      this.openDialog = false
      this.$router.push({
        name: 'InfoAuth',
      })
    },
  },
  created() {},
  computed: {
    openDialog: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('closeDialog', val)
        // 关闭弹窗
        if (!val) {
          // 记录打开次数
          recordMsgCount()
            .then((res) => {})
            .catch((err) => {
              console.log(err)
            })
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-vertical {
  ::v-deep.el-dialog {
    background-color: transparent !important;
  }
}
// .dialog-vertical {
//   ::v-deep.el-dialog {
//     border-radius: 12px;
//   }
//   ::v-deep.el-dialog__header {
//     padding: 40px 40px 24px;
//   }
//   ::v-deep.el-dialog__body {
//     padding: 0px 40px 40px;
//   }

//   ::v-deep.el-dialog__headerbtn {
//     top: 37px;
//     right: 40px;
//   }
// }

.cover {
  width: 100%;
  cursor: pointer;
}
</style>
