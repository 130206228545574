<!-- copy <notification> 的 -->
<template>
  <el-drawer
    :visible.sync="show"
    direction="rtl"
    append-to-body
    :withHeader="false"
    :size="drawerWidth"
    modalClass="notification-modal"
    class="notification"
    @open="onOpen"
    @close="onColse"
  >
    <transition-group tag="div" name="el-fade-in">
      <div v-show="!view" class="message" v-loading="loading" key="message">
        <div class="head">
          <h2 class="title">消息中心</h2>
          <el-button v-if="msgData.length" type="text" class="btn" @click="onEmptyMsg"
            >清空</el-button
          >
        </div>
        <div class="body">
          <el-scrollbar class="scrollbar" wrap-class="list">
            <div
              class="infinite-list"
              v-infinite-scroll="onPageLoad"
              infinite-scroll-distance="10"
            >
              <div
                v-for="item in msgData"
                :key="item.id"
                class="msg-item"
                :class="{ read: item.is_read === 1 }"
                @click="onView(item)"
              >
                <div class="item-content">
                  <div class="item-head">
                    <span class="type" :title="item.message_title">{{
                      item.message_title | placeholder
                    }}</span>
                    <time class="time">{{
                      item.create_time | placeholder
                    }}</time>
                    <div class="btn-group">
                      <el-button
                        v-if="item.is_read !== 1"
                        type="text"
                        class="btn"
                        @click.stop="onRead(item.id)"
                      >
                        标为已读
                      </el-button>
                      <el-button
                        type="text"
                        class="btn"
                        @click.stop="onDelete(item.id)"
                        >删除</el-button
                      >
                    </div>
                  </div>
                  <div class="item-body">
                    <div
                      :class="[
                        item.message_sign === 'new_contract' ||
                        item.message_sign === 'invoice_checked'
                          ? 'complete'
                          : 'value',
                      ]"
                    >
                      {{ item.content | placeholder }}
                      <el-button
                        v-if="item.message_sign === 'new_contract'"
                        style="padding: 0"
                        type="text"
                        @click.stop="
                          viewDialog('contractDetail', item.contract)
                        "
                        >点击查看</el-button
                      >
                      <el-button
                        v-if="item.message_sign === 'invoice_checked'"
                        style="padding: 0"
                        type="text"
                        @click.stop="viewDialog('receiptDetail', item.invoice)"
                        >点击查看</el-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="footer">
              <div v-if="pageLoad" class="footer-item load">
                加载中<span class="dotting"></span>
              </div>
              <div v-else-if="msgData.length === 0 && pageLoadEnd" style="height: 70vh;" class="flex-center">
                <el-empty />
              </div>
              <div v-else-if="pageLoadEnd" class="footer-item end">没有更多了</div>
            </div>
          </el-scrollbar>
        </div>
      </div>
      <div v-show="view" class="details" key="details">
        <div class="head">
          <el-button
            type="text"
            class="btn"
            icon="el-icon-back"
            @click="onBack"
          ></el-button>
          <h2 class="title">{{ viewData.message_title | placeholder }}</h2>
        </div>
        <div class="body">
          <el-scrollbar class="scrollbar" wrap-class="list">
            <div class="details-content">
              {{ viewData.content | placeholder }}
            </div>
          </el-scrollbar>
        </div>
      </div>
    </transition-group>
    <!-- <contract-detail-dialog ref="contractDetail" />
    <receipt-detail-dialog ref="receiptDetail" /> -->
  </el-drawer>
</template>

<script>
import {
  messageRecords,
  readMessage,
  delMessage,
  delAllMessage, userCenterMessages, deleteCenterMessage, makeCenterMessageRead, deleteAllCenterMessage,
} from "../api/notification";
import _ from "lodash";
// import ContractDetailDialog from "../../my-project/components/common/ContractDetailDialog";
// import ReceiptDetailDialog from "../../my-project/components/common/ReceiptDetailDialog";
export default {
  name: 'user-center-notification',
  // components: {ReceiptDetailDialog, ContractDetailDialog},
  model: {
    prop: "open",
    event: "handleOpen",
  },
  props: {
    //是否显示
    open: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      drawerWidth: 304, //宽度
      loading: false, //加载中
      postData: {}, //提交的数据
      msgData: [], //消息列表数据
      pageData: {}, //分页数据
      pageLoad: false, //page加载中动画
      pageLoadEnd: false, //page到最末尾了
      isOneLoad: true, //是否为第一次加载数据
      view: false, //是否查看详情
      viewData: {}, //查看的数据
    };
  },
  computed: {
    show: {
      get(app) {
        return this.open;
      },
      set(val) {
        this.$emit("handleOpen", val);
      },
    },
    //header数据
    // headerData() {
    //   return this.$store.getters['header/headerData'];
    // },
  },
  methods: {
    viewDialog(target, data) {
      let options = { orderid: data.orderid };
      if (target === "contractDetail") {
        options.number = data.contract_number;
        options.time = data.contract_apply_time;
      } else {
        options = data;
      }
      this.$refs[target].open(options);
    },
    //获取消息列表
    getMsgList(type = "replace") {
      type === "push" ? (this.pageLoad = true) : (this.loading = true);
      this.pageLoadEnd = false;
      return userCenterMessages(this.postData)
        .then((res) => {
          const { data } = res;
          this.pageData = data;
          if (type === "push") {
            this.msgData.push(...data.data);
            this.pageLoad = false;
          } else {
            this.msgData = data.data;
            this.isOneLoad = false; //第一次结束
            this.loading = false;
            this.updateHeaderMsgCount()
          }
          //没有了
          if (this.postData.page >= this.pageData.last_page) {
            this.pageLoadEnd = true;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //打开的回调
    onOpen() {
      if (this.view) return; //跳过
      Object.assign(this.postData, { page: 1 });
      this.getMsgList();
      //更新header的通知数量
      // this.$store.dispatch("header/getHeaderData");
    },
    //无限下一页
    onPageLoad() {
      if (this.isOneLoad) return; //首次不进行触发
      const { page } = this.postData;
      if (page < this.pageData.last_page) {
        Object.assign(this.postData, { page: page + 1 });
        this.getMsgList("push");
      }
    },
    // 标记已读
    onRead(id) {
      this.loading = true;
      const postData = { id: id };
      makeCenterMessageRead(postData)
        .then(() => {
          this.msgData.find((item) => {
            if (item.id === id) {
              item.is_read = 1;
              return true;
            }
          });
          //更新通知数量
          this.updateHeaderMsgCount();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //删除
    onDelete(id) {
      this.loading = true;
      const postData = { id: id };
      deleteCenterMessage(postData)
        .then(() => {
          const index = this.msgData.findIndex((item) => item.id === id);
          if (index !== -1) this.msgData.splice(index, 1);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //清空
    onEmptyMsg() {
      this.loading = true;
      deleteAllCenterMessage()
        .then(() => {
          this.getMsgList();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //查看
    onView(data) {
      // 如果是公众号的消息直接跳转公众号链接  category = 1
      if (data.category === 1) {
        window.open(
          data.wx_url,
          "target"
        );
      }// 不是公众号的消息打开详情
       else {
        this.viewData = data;
        this.view = true;
      }

      //触发已读
      // if (data.is_read !== 1) this.onRead(data.id);
      this.onRead(data.id);
    },
    //返回
    onBack() {
      this.view = false;
    },
    //关闭的回调
    onColse() {
      this.$emit("close");
    },
    //header的通知数量-1
    updateHeaderMsgCount() {
      // const copyHD = _.cloneDeep(this.headerData);
      // if (copyHD.new_message_count > 0) {
      //   copyHD.new_message_count = copyHD.new_message_count - 1;
      // }
      // this.$store.commit("header/setHeaderData", copyHD);
      this.$emit('update')
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  // background-color: rgba(0, 0, 0, 0.1);
  ::v-deep.el-drawer {
    box-shadow: 0px 0px 4px 0px rgba(0, 21, 41, 0.12);
    .el-drawer__body {
      position: relative;
    }
  }
  .message {
    height: 100vh;
    > .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 51px;
      margin: 0 24px;
      padding: 15px 0;
      border-bottom: 1px solid #eee;
      .title {
        font-size: 18px;
        font-weight: normal;
        line-height: 1;
        color: #3a3a3a;
      }
      .btn {
        font-size: 14px;
        padding: 3px;
      }
    }
    > .body {
      height: calc(100vh - 51px);
      .scrollbar {
        height: 100%;
        ::v-deep.list {
          overflow-x: hidden;
        }
      }
      .msg-item {
        border-bottom: 1px solid #eee;
        background-color: #fff;
        cursor: pointer;
        transition: background-color 0.25s;
        &.read {
          .item-content {
            .item-head {
              .type,
              .time {
                color: #bbb;
              }
            }
            .item-body {
              color: #bbb;
            }
          }
        }
        &:hover {
          background-color: #f6f7fb;
          .item-content {
            .item-head {
              .time {
                display: none;
              }
              .btn-group {
                display: inline;
              }
            }
          }
        }
        .item-content {
          margin: 0 24px;
          .item-head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 50px;
            font-size: 14px;
            line-height: 1;
            .type,
            .time {
              color: #666;
            }
            .type {
              max-width: 100px;
              @include nowrap();
            }
            .btn-group {
              flex-shrink: 0;
              display: none;
              .btn {
                padding: 0;
                & + .btn {
                  margin-left: 5px;
                }
              }
            }
          }
          .item-body {
            padding-bottom: 20px;
            font-size: 14px;
            line-height: 1.7;
            color: #000;

            .complete,
            .value {
              letter-spacing: 1px;
              white-space: pre-wrap;
              word-wrap: break-word;
            }

            .value {
              @include clamp(2);
            }
          }
        }
      }
      .footer {
        .footer-item {
          padding: 20px;
          text-align: center;
          color: #666;
          &.load {
            .dotting {
              display: inline-block;
              height: 1em;
              line-height: 1;
              text-align: left;
              vertical-align: -0.25em;
              overflow: hidden;
              &::before {
                display: block;
                content: "...\A..\A.";
                white-space: pre-wrap;
                animation: dot 3s infinite step-start both;
              }
              @keyframes dot {
                33% {
                  transform: translateY(-2em);
                }
                66% {
                  transform: translateY(-1em);
                }
              }
            }
          }
        }
      }
    }
  }
  // 详情
  .details {
    height: 100vh;
    > .head {
      height: 51px;
      margin: 0 24px;
      padding: 15px 0;
      border-bottom: 1px solid #eee;
      position: relative;
      .title {
        font-size: 18px;
        font-weight: normal;
        line-height: 1;
        color: #3a3a3a;
        text-align: center;
        padding: 0 30px;
        @include nowrap();
      }
      .btn {
        position: absolute;
        top: 12px;
        left: 0;
        font-size: 18px;
        height: 26px;
        padding: 0;
        padding-left: 0;
        color: #3a3a3a;
        &:hover {
          color: #3576ff;
        }
        &:active {
          opacity: 0.7;
        }
      }
    }
    > .body {
      height: calc(100vh - 51px);
      .scrollbar {
        height: 100%;
        ::v-deep.list {
          overflow-x: hidden;
        }
      }
      .details-content {
        margin: 0 24px;
        padding: 20px 0;
        border-bottom: 1px solid #eee;
        font-size: 14px;
        line-height: 1.7;
        color: #000;
        letter-spacing: 1px;
        white-space: pre-wrap;
        word-wrap: break-word;
      }
    }
  }
}
</style>
<style lang="scss">
.notification-modal {
  opacity: 0.1;
}
</style>
