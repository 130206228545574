<!--
 * @Author: dongjia
 * @Date: 2022-04-19 09:49:36
 * @LastEditTime: 2022-04-19 10:43:46
 * @LastEditors: aleaner
 * @Description: 套餐服务到期提示弹窗
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\admin\components\ServerTipsDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="服务续费" :visible.sync="openDialog" width="582px" class="dialog-vertical"
  close-on-click-modal close-on-press-escape>
    <div class="tips-list">
      <div class="list-item" :style="{
        backgroundColor: subColor(item)
      }" v-for="(item, index) in serverTips" :key="index">
        <div class="item-content">
          <span class="el-icon-warning" :style="`color: ${mainColor(item)}`"></span>
          <div class="content" :style="`color: ${mainColor(item)}`">{{item.msg}}</div>
        </div>
        <div class="item-btn">
          <el-button class="btn" size="mini" :style="`background-color: ${mainColor(item)}`"
            @click="goToPayInIframe(item)">立即购买</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    // 获取项目主颜色
    mainColor(item) {
      return item.type === "package" ? "#E63C3C" : "#E6A23C";
    },
    // 获取项目副颜色
    subColor(item) {
      return item.type === "package" ? "#FDECEC" : "#FDF6EC";
    },
  },
  created() {},
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
    // 项目服务到期提示数据
    serverTips() {
      return this.$store.getters["header/headerData"].tips;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-vertical {
  ::v-deep.el-dialog {
    border-radius: 12px;
  }
  ::v-deep.el-dialog__header {
    padding: 40px 40px 24px;
  }
  ::v-deep.el-dialog__body {
    padding: 0px 40px 40px;
  }

  ::v-deep.el-dialog__headerbtn {
    top: 37px;
    right: 40px;
  }
  .tips-list {
    .list-item + .list-item {
      margin-top: 24px;
    }
    .list-item {
      padding: 16px;
      border-radius: 4px;
      .item-content {
        display: flex;
        .el-icon-warning {
          margin-right: 8px;
          flex-shrink: 0;
        }
        .el-icon-warning,
        .content {
          font-size: 14px;
          line-height: 21px;
        }
      }
      .item-btn {
        width: 100%;
        margin-top: 8px;
        display: flex;
        justify-content: flex-end;
        .btn {
          border: none;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
