import api from "@/base/utils/request";

//侧边栏数据
export const menuData = data => {
  return api({
    url: "/admin/admin/menu/index",
    method: "post",
    data
  });
}

export const getUpdateList = () => {
  return api({
    url: '/admin/admin/data_init/features',
    method: 'post'
  })
}

export const initFeature = (feature) => {
  return api({
    url: '/admin/admin/data_init/init',
    method: 'post',
    data: {feature},
    notCancel: true
  })
}

export const initSuccess = () => {
  return api({
    url: '/admin/admin/data_init/initSuccess',
    method: 'post'
  })
}
