<!--
 * @Author: dongjia
 * @Date: 2022-04-18 17:43:25
 * @LastEditTime: 2022-04-19 10:42:29
 * @LastEditors: aleaner
 * @Description: 项目服务到期提示模块
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\admin\components\ServerTips.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div v-if="serverTips && serverTips.length" class="tips-bar">
    <div class="collapse-area cursor-pointer" :style="`background-color: ${mainColor}`"
      @click="isCollapse = !isCollapse"></div>
    <div class="tips-box" :style="{
      backgroundColor: bgColor,
      height: isCollapse?'0':'53px'
    }">
      <span class="el-icon-warning" :style="`color: ${mainColor}`"></span>
      <div class="tip-content" :style="`color: ${mainColor}`">
        {{serverTips[0].msg}}</div>
      <el-button class="tip-btn" size="mini" :style="`background-color: ${mainColor}`"
        @click="goToPayInIframe(serverTips[0])">立即购买</el-button>
      <div class="box-right">
        <div v-if="serverTips.length - 1" class="more-server cursor-pointer"
          :style="`background-color: ${chackBoxColor};color: ${mainColor}`" @click="showTipsDialog = true">
          还有 {{serverTips.length - 1}} 项服务<span class="el-icon-arrow-right"
            :style="`color: ${mainColor}`"></span></div>
        <span class="el-icon-remove-outline cursor-pointer" :style="`color: ${mainColor}`"
          @click="isCollapse = !isCollapse"></span>
      </div>
    </div>
    <ServerTipsDialog v-model="showTipsDialog" />
  </div>
</template>

<script>
import ServerTipsDialog from "./ServerTipsDialog";
export default {
  components: { ServerTipsDialog },
  data() {
    return {
      isCollapse: false,
      showTipsDialog: false,
    };
  },
  computed: {
    // 项目服务到期提示数据
    serverTips() {
      return this.$store.getters["header/headerData"].tips;
    },
    // 文本颜色
    mainColor() {
      return this.serverTips[0] && this.serverTips[0].type === "package"
        ? "#E63C3C"
        : "#E6A23C";
    },
    // 背景颜色
    bgColor() {
      return this.serverTips[0] && this.serverTips[0].type === "package"
        ? "#FDECEC"
        : "#FDF6EC";
    },
    // 查看更多背景色
    chackBoxColor() {
      return this.serverTips[0] && this.serverTips[0].type === "package"
        ? "rgba(230, 60, 60, 0.1)"
        : "rgba(230, 162, 60, 0.1)";
    },
  },
};
</script>

<style lang="scss" scoped>
.tips-bar {
  width: 100%;
  .collapse-area {
    height: 4px;
    &:hover {
      opacity: 0.8;
    }
  }
  .tips-box {
    overflow: hidden;
    padding: 0 24px;
    display: flex;
    align-items: center;
    transition: all 0.2s ease;
    .el-icon-warning {
      font-size: 14px;
      flex-shrink: 0;
    }
    .tip-content {
      margin-left: 10px;
      font-size: 14px;
      color: #e6a23c;
      line-height: 16px;
    }
    .tip-btn {
      border: none;
      color: #ffffff;
      margin-left: 16px;
    }
    .box-right {
      margin-left: auto;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      .more-server {
        padding: 6px 8px;
        display: flex;
        align-items: center;
        background: rgba(230, 162, 60, 0.1);
        border-radius: 4px;
        font-size: 12px;
        line-height: 1;
        .el-icon-arrow-right {
          font-size: 9px;
          margin-left: 4px;
        }
      }
      .el-icon-remove-outline {
        font-size: 16px;
        margin-left: 16px;
      }
    }
  }
}
</style>
