<template>
  <div
    :class="{ new: isNew }"
    class="three-navigation"
    v-if="routerArr.length > 1"
  >
    <el-tabs v-if="delay" v-model="activeName" @tab-click="onRouterJump">
      <el-tab-pane
        :key="route.url"
        :label="route.name"
        :name="route.url"
        v-for="(route, i) in routerArr"
      >
        <template #label
          >{{
            `${route.name}${route.total > 0 ? '（' + route.total + '）' : ''}`
          }}
          <!--        {{messagesData}}-->
          <!--          {{messagesData[routeName]}}-->
          <!--        {{$route.name}}-->
          <!--
           && ['handling', 'checking', 'unchecked', 'waite_deliver', 'stayCheck'].includes(tab.name)
          -->
          <el-badge
            :hidden="!(messagesData[route.url] > 0)"
            :value="messagesData[route.url] || ''"
            style="overflow: visible"
            v-if="messagesData && messagesData[route.url]"
          ></el-badge>
        </template>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  props: {
    //三级菜单数据
    navigationData: {
      type: Object,
      required: true,
    },
    isNew: Boolean,
  },
  data() {
    return {
      activeName: '', //默认选中
      delay: false, // el-tabs 下划线出不来的解决方式
    }
  },
  computed: {
    ...mapState('socket', ['messagesData']),
    // 路由name
    routeName() {
      /*
      meta.alias 为 navigationData 对象的键名，和后台数据返回的 url 一致，示例：
      // 后台接口菜单数据片段
      const data = {
        "node": "cyc_ask",
        "name": "信息反馈",
        "is_open_new_window": 0,
        "url": "CycAskList", // meta.alias
        "icon": "https://testsaasapi.shetuan.pro/static/cyc/images/admin_node_icons/ask_normal.png",
        "icon_selected": "https://testsaasapi.shetuan.pro/static/cyc/images/admin_node_icons/ask_selected.png",
        "children": [
          {
            "node": "cyc_ask_list",
            "name": "反馈列表",
            "is_open_new_window": 0,
            "url": "CycAskList",
            "icon": "",
            "icon_selected": ""
          },
          {
            "node": "cyc_ask_category",
            "name": "反馈类别",
            "is_open_new_window": 0,
            "url": "CycAskCategory",
            "icon": "",
            "icon_selected": ""
          }
        ]
      }

     // 当前路由
     $route = {name: 'CycAskListIndex', meta: { alias: "CycAskList" }}

     // 模块路由配置片段
     const route = {
        // 信息反馈
        path: "leave_msg", name: "CycAskList", component: { render: h => h("router-view") },
        redirect: { name: "CycAskListIndex" },
        children: [
          // 反馈列表
          { path: "leave_list", name: "CycAskListIndex", component: () => import("../views/leavemsg-list"),
            meta: { alias: "CycAskList" } // alias 表示 navigationData 对象的 key 值【上文中的 data 的 url】
            },
          { path: "leave_detail/:id", name: "MsgDetail", component: () => import("../views/leavemsg-detail"), meta: { title: "查看" } }
        ]
      },
 */
      return this.$route.meta?.alias || this.$route.name
    },
    //菜单数据
    routerArr() {
      let arr = []
      Object.keys(this.navigationData).forEach((key) => {
        const data = this.navigationData[key]
        if (data.find((item) => item.url === this.routeName)) {
          arr = data
        }
      })
      return arr
    },
  },
  methods: {
    //路由跳转
    onRouterJump() {
      if (this.activeName === this.routeName) {
        //相同路由进行刷新
        this.$store.commit('setRefresh', true)
      } else {
        this.$router.push({ name: this.activeName })
      }
    },
  },
  mounted() {
    this.activeName = this.routeName ?? ''

    setTimeout(() => {
      this.delay = true
    }, 10) // el-tabs 下划线出不来的解决办法
  },
  watch: {
    routeName(val) {
      if (val !== this.activeName) this.activeName = val ?? ''
    },
  },
}
</script>
<style lang="scss" scoped>
.three-navigation {
  margin-bottom: 28px;

  &.new {
    margin: -9px 0;

    ::v-deep .el-tabs__nav-wrap:after {
      display: none;
    }

    ::v-deep .el-tabs__item {
      height: 50px;
      line-height: 50px;
    }
  }
}
</style>
