/*
 * @Author: jun
 */
import api from '@/base/utils/request';

//顶部消息通知
export const messageRecords = data => {
  return api({
    url: '/admin/admin/user/messageRecords',
    method: 'post',
    data
  });
};
//消息标记已读
export const readMessage = data => {
  return api({
    url: '/admin/admin/user/readMessage',
    method: 'post',
    data
  });
};
//删除消息
export const delMessage = data => {
  return api({
    url: '/admin/admin/user/delMessage',
    method: 'post',
    data
  });
};
//删除全部消息
export const delAllMessage = data => {
  return api({
    url: '/admin/admin/user/delAllMessage',
    method: 'post',
    data
  });
};

// 用户中心消息
export const userCenterMessages = (data) => {
  return api({
    url: '/admin/admin/messageSystem/userCenterMsg',
    method: 'post',
    data
  })
}

export const deleteCenterMessage = (data) => {
  return api({
    url: '/admin/admin/messageSystem/delCenterMsg',
    method: 'post',
    data
  })
}

export const makeCenterMessageRead = (data) => {
  return api({
    url: '/admin/admin/messageSystem/readCenterMsg',
    method: 'post',
    data
  })
}

export const deleteAllCenterMessage = () => {
  return api({
    url: '/admin/admin/messageSystem/delAllCenterMsg',
    method: 'post'
  })
}
