<template>
  <div :class="[template||'v1']" class="nav-container">
<!--    {{routeName}} ## {{breadcrumbArr.length}}-->
    <el-breadcrumb id="breadcrumb" separator="/" v-if="breadcrumbArr.length">
      <el-breadcrumb-item v-for="(item,index) in breadcrumbArr" :key="item.path">
        <template v-if="index !== breadcrumbArr.length-1">
          <!-- 面包屑配置优先 .breadcrumb.name||item.name,item.redirect-->
          <a @click="goRoute(item)" href="#">{{item|breadcrumbTitle}}</a>
        </template>
        <span v-else>{{item|breadcrumbTitle}}</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>
<script>
export default {
  props: {
    root: {
      type: Object,
      default: null
    },
    template: String, // 'v1', 'v2'
  },
  data() {
    return {
      routerMeta: {},
    };
  },
  computed: {
    //当前路由名
    routeName() {
      return this.$route.name;
    },
    //面包屑数据
    breadcrumbArr() {
      let arr = [];
      // console.log(this.$route.matched, 'matched************')
      this.$route.matched.forEach((item, index) => {
        // console.log(item)
        const name = item.name || item.meta?.breadcrumb?.name;

        /* 此页面是否属于右侧内置tabs菜单指向的页面 */
        const isInThreeNav = !!(this.root?.children?.length) && !!(this.root?.children?.find(el => el.url === name))
        /* 右侧内置tabs菜单使用后台返回的名称 */
        let rightNavActive = this.root?.children?.find(el => el.url === name) || {}
        // console.log(index, name, this.root?.url, this.root?.name , '$$$$$$$$$$$$')
        const title =
          isInThreeNav && rightNavActive.url === (item.meta?.alias || name) && rightNavActive.name ||
          item.meta?.title || item.meta?.breadcrumb?.title
          || ((item.name && item.name === this.root?.url && !(this.root?.children?.length)) ? this.root?.name : '') || '';

        const hide = item.meta?.breadcrumb?.hide;

        // if (['ClassroomMaterialManageIndex', 'CollegeTeacherList', 'CollegeTeacherListIndex'].includes(this.routeName) || this.routeName === 'CollegeTeacherList') {
        //   console.log('匹配中的路由', item.path)
        //   console.log('读取路由名和面包屑的', name)
        //   console.log('读取 title', title || '--')
        //   console.log('看看 root', this.root)
        //   console.log('是否与root.url一致', name === this.root?.url, name, this.root?.url)
        //   console.log('是否右侧内置tabs', isInThreeNav)
        //   console.log('右侧内置tabs object', rightNavActive, item.meta?.alias)
        //   console.log('右侧内置菜单名', isInThreeNav && rightNavActive.url === (item.meta?.alias || name) && rightNavActive.name)
        //   console.log('meta名', item.meta?.title || item.meta?.breadcrumb?.title)
        //   console.log('root名', ((item.name === this.root?.url && !(this.root?.children?.length)) ? this.root?.name : '') )
        //   console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%')
        // }

        if (name && title && !hide) {
          const breadcrumb = item.meta?.breadcrumb || {title};
          arr.push({ ...item, breadcrumb });
        }
      });
      // console.log(arr.length, arr)
      return arr;
    },
  },
  filters: {
    //面包屑标题
    breadcrumbTitle(obj) {
      return obj?.breadcrumb?.title || obj?.meta?.title || "";
    },
  },
  methods: {
    //路由跳转
    goRoute(route) {
      const name = route?.breadcrumb?.name || route.name;
      const redirectName = route?.redirect?.name;
      let nextRoute = null;
      //存在重定向，重定向优先
      if (redirectName && redirectName !== this.routeName) {
        nextRoute = { name: redirectName };
      } else if (!redirectName && name && name !== this.routeName) {
        nextRoute = { name };
      } else {
        return;
      }
      //加入路由参数
      if (nextRoute && this.routerMeta[nextRoute.name]) {
        Object.assign(nextRoute, this.routerMeta[nextRoute.name]);
      }
      this.$router.push(nextRoute);
      //清理
      this.cleanOldData();
    },
    //清理旧数据
    cleanOldData() {
      const metaKeys = Object.keys(this.routerMeta);
      this.breadcrumbArr.forEach((item) => {
        if (!metaKeys.includes(item.name)) {
          delete this.routerMeta[item.name];
        }
      });
    },
  },
  watch: {
    $route: {
      handler(to) {
        this.routerMeta[to.name] = {
          params: to.params,
          query: to.query,
        };
        // console.log(this.routerMeta);
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
#breadcrumb {
  height: 30px;
  line-height: 30px;
  ::v-deep.el-breadcrumb__separator {
    font-weight: normal;
  }
  .el-breadcrumb__inner.is-link,
  .el-breadcrumb__inner a {
    font-weight: normal;
    color: #c0c4cc;
  }
  .el-breadcrumb__inner.is-link:hover,
  .el-breadcrumb__inner a:hover {
    color: $primary;
  }
  .el-breadcrumb__item {
    &:last-child {
      .el-breadcrumb__inner span {
        color: $regularText;
      }
    }
  }
}

.v2 {
  #breadcrumb {
    .el-breadcrumb__inner.is-link,
    .el-breadcrumb__inner a {
      font-weight: normal;
      color: #B3B3B3;
    }

    .el-breadcrumb__item {
      &:last-child {
        .el-breadcrumb__inner span {
          color: #808080;
        }
      }
    }
  }
}
</style>
