var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    '--group-w': _vm.refactor ? '90px' : '0px',
    '--mini-sidebar-w': '64px',
    '--left-w':
      _vm.refactor && _vm.simple
        ? '90px'
        : _vm.isCollapse
        ? _vm.refactor
          ? "calc(64px + 90px)" || '154px'
          : '64px'
        : _vm.refactor
        ? ("calc(90px + " + _vm.sidebarW + "px)")
        : (_vm.sidebarW + "px"),
    '--sidebar-w': (_vm.sidebarW + "px"),
    '--header-h': '60px',
  }),attrs:{"id":"admin"}},[_c('Header',{attrs:{"fullscreen-names":_vm.fullscreenNames,"offSidebarChildren":_vm.onOffSidebarChildren}}),_c('Sidebar',{ref:"mySidebar",attrs:{"sidebar-data":_vm.sidebarData,"navigation-data":_vm.navigationData,"refactor":_vm.refactor,"uniqueOpened":"","reset-sidebar":_vm.resetSidebar,"template":_vm.sidebarTemplate},on:{"resize":_vm.onResize}}),_c('main',{directives:[{name:"show",rawName:"v-show",value:(!_vm.sidebarLoading),expression:"!sidebarLoading"}],class:['flex-col'],style:({
      minWidth: _vm.$route.meta && _vm.$route.meta.fitContent ? 'fit-content' : '',
    }),attrs:{"id":"main"}},[_c('ai-chat-dialog',{ref:"chatDialog"}),(!Object.keys(_vm.iframeRouteData).includes(_vm.$route.name))?_c('div',{staticClass:"navbar"},[(!Object.keys(_vm.navigationData).length || !_vm.isInlineNav)?_c('Breadcrumb',{attrs:{"root":_vm.rootBreadcrumb,"template":_vm.sidebarTemplate}}):_c('three-navigation',{attrs:{"navigation-data":_vm.navigationData,"is-new":""}})],1):_vm._e(),_c('ServerTips'),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.mainLoading),expression:"mainLoading"}],ref:"main-container",staticClass:"main-container flex-col",class:{
        involved: _vm.involvedNames.includes(_vm.$route.name),
        noPadding: _vm.noPaddingNames.includes(_vm.$route.name),
      },attrs:{"id":"main-container"}},[(!_vm.refresh)?_c('router-view'):_c('router-loading')],1)],1),_c('upgrade-dialog',{attrs:{"show":_vm.upgrade.show,"progress":_vm.upgrade.progress},on:{"confirm":_vm.handleFinish}}),_c('MediaDialog'),_c('crop-dialog',{attrs:{"is-dialog":true}}),_c('PlatformPromotionDialog',{model:{value:(_vm.showPlatFormPromotion),callback:function ($$v) {_vm.showPlatFormPromotion=$$v},expression:"showPlatFormPromotion"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }