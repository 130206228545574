<template>
  <el-dialog v-if="show" :visible="show" title="" width="540px" class="upgrade-dialog dialog-vertical"
    :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" destroy-on-close>
    <div class="flex-col flex-center" style="margin-bottom: 80px">
      <img style="margin-top: 47px" width="136px" src="../assets/images/up.png" />
      <p v-if="progress < 100" class="upgrade-info">系统升级中,请耐心等待…</p>
      <p v-else class="upgrade-info">系统升级成功！</p>
      <el-progress style="width: 300px; margin-top: 35px; margin-left: 40px;" :percentage="progress"
        :status="progress === 100 ? 'success':undefined"></el-progress>
    </div>
    <template #footer>
      <el-button v-if="progress < 100" type="info" disabled size="medium">完成升级</el-button>
      <el-button v-else type="primary" size="medium" @click="finish">完成升级</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: "UpgradeDialog",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    progress: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  methods: {
    finish() {
      this.$emit("confirm");
    },
  },
};
</script>

<style lang="scss" scoped>
.upgrade-dialog {
  .upgrade-info {
    margin-top: 42px;
    font-size: 14px;
    color: #3a3a3a;
    line-height: 20px;
  }

  .el-dialog__footer,
  ::v-deep.el-dialog__footer {
    text-align: center !important;
    padding-bottom: 40px;
  }
}
</style>
